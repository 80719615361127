import chatsApi from "api/chats";
import classNames from "classnames";
import Bubble from "components/chat/Bubble";
import Header from "components/chat/Header";
import Input from "components/chat/Input";
import ChatSkeletons from "components/theme/skeleton/ChatSkeletons";
import constants from "configs/constants";
import events from "configs/events";
import useEvent from "hooks/useEvent";
import useToast from "hooks/useToast";
import useBadges from "queries/badges";
import useBlocked from "queries/blocked";
import useChats, {
  hideMessage,
  readMessage,
  reportMessage,
} from "queries/chats";
import useEngage from "queries/engage";
import useThreadQuery, {
  addThreadMessage,
  removeThMessage,
} from "queries/thread";
import useUser from "queries/user";
import useUsers from "queries/users";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Message from "types/Message";
import TUser from "types/TUser";
import bsonId from "utils/bsonId";
import isRemoved from "utils/isRemoved";

const UNSENT_MESSAGE = "رسالة محذوفة";

interface Remove {
  chatId: string;
  messageId: string;
}

const getUtils = ({ threadQuery, chat, receiverQuery, engagements }: any) => {
  const receiver: TUser = receiverQuery?.user?.account || {};

  const isLoading: boolean =
    threadQuery.isLoading || chat.isLoading || receiverQuery.isLoading;
  const isError = threadQuery.isError || chat.isError || receiverQuery.isError;

  const isMyEngage = engagements.data
    ? !!engagements.data.find(
        (e: any) => e.user._id === receiver?._id && e.status === "accepted",
      )
    : false;

  const messages = (threadQuery.thread || [])
    .filter((m: any) => !m.unsent)
    .map((m: any) =>
      m.visible ? m : { ...m, text: UNSENT_MESSAGE },
    ) as Message[];

  return { receiver, messages, isLoading, isError, isMyEngage };
};

const ThreadPage = () => {
  const { chatId = "", receiverId = "" } = useParams();

  const receiverQuery = useUsers({ uid: receiverId || "" });
  const { isBlockedMe } = useBlocked(receiverId);
  const threadQuery = useThreadQuery({ chatId });
  const { setBadges } = useBadges();
  const engagements = useEngage();
  const { user } = useUser();
  const chat = useChats();

  const { receiver, messages, isLoading, isError, isMyEngage } = getUtils({
    threadQuery,
    chat,
    receiverQuery,
    engagements,
  });

  const path = `/${isMyEngage ? "engaged" : "users"}/profile/${receiver?.uid}`;
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const isLoadMore = useRef(false);
  const isFocus = useRef(false);
  const toast = useToast();

  const getPreviousMessage = (
    messageId: string,
    fn: (p: Remove) => Message[] | undefined,
  ) => {
    return fn({ chatId, messageId })!.filter(
      (m) => !m.unsent && m._id !== messageId,
    )[0];
  };

  const onSend = (message: string) => {
    if (!message) return;

    const id = bsonId();
    const isReceiverEngage = ["engaged", "married"].includes(receiver!.status!);

    // blocked user
    if (isBlockedMe.me) return toast.error(constants.TOAST.USER_BLOCKED_YOU);

    if (isBlockedMe.who)
      return toast.error(`لقد قام ${receiver!.name} بحظر التواصل معك`);

    // if target in (engaged, married, delete) status => not accepted action
    if (isRemoved(receiver)) return toast.error(isRemoved(receiver));
    else if (!isMyEngage && isReceiverEngage)
      return toast.error(constants.TOAST.GIRL_ENGAGED_PROFILE);
    else {
      chat.send({ id, type: "text", receiver: receiverId, message });
      addThreadMessage({
        messageId: id,
        message,
        sender: user,
        chatId,
        type: "text",
      });

      chatsApi.send({ id, type: "text", receiver: receiverId, message });
    }
  };

  const reportingMessage = (messageId: string) => {
    const th = threadQuery.thread.find((th) => th._id == messageId)!;

    if (th.reported) toast.warn(constants.TOAST.YOU_HAVE_REPORT_BEFORE);
    else {
      chatsApi.report({ receiver: receiverId, messageId, message: th.text });
      toast.success(constants.TOAST.REPORT_SUCCESSFUL);
      threadQuery.reportMessage({ chatId, messageId });
      reportMessage({ userId: receiverId, messageId });
    }
  };

  const hidingMessage = (messageId: string) => {
    const message = getPreviousMessage(messageId, threadQuery.unsentMessage);

    hideMessage({ userId: receiverId, messageId, message });
    chatsApi.hide({ receiver: receiverId, messageId, fromAll: false });
  };

  const hideRemovedMessage = (messageId: string) => {
    const message = getPreviousMessage(messageId, threadQuery.unsentMessage);

    chat.remove({ userId: receiverId, messageId, message });
    threadQuery.unsentMessage({ chatId, messageId });
  };

  const removeMessageFromAll = (messageId: string) => {
    const current = threadQuery.thread.find((th) => th._id === messageId);
    const message = getPreviousMessage(messageId, removeThMessage);

    hideMessage({
      userId: receiverId,
      messageId,
      message: message || { ...current, text: constants.MESSAGE_REMOVED },
    });

    chatsApi.hide({ receiver: receiverId, messageId, fromAll: true });
  };

  const makeMessageAsRead = () => {
    isFocus.current = true;
    const selectedChat = (chat.chats || []).find(
      (c) => c?._id === chatId && c?.lastMessage?.user?._id !== user._id,
    );
    const seen = selectedChat ? selectedChat?.messageSeen : true;

    if (!seen) {
      chatsApi.read(chatId);
      readMessage(receiverId);
      setBadges((b) => ({ ...b, chats: Math.max(0, b.chats - 1) }));
    }
  };

  const calculatePrevAndNext = (messages: Message[], currentIndex: number) => {
    const totalMessages = messages.length;
    const normalizeIndex = (index: number) =>
      index < 0 || index >= totalMessages ? 0 : index;

    const prevIndex = normalizeIndex(currentIndex - 1);
    const nextIndex = normalizeIndex(currentIndex + 1);

    return {
      prevMessage: {
        index: !currentIndex ? -1 : prevIndex,
        time: messages[prevIndex].createdAt,
        id: messages[prevIndex].user._id,
      },
      nextMessage: {
        index: nextIndex,
        time: messages[nextIndex || currentIndex].createdAt,
        id: messages[nextIndex || currentIndex].user._id,
      },
    };
  };

  // on receive new message
  useEvent({
    event: events.GOT_MESSAGE,
    listener: () => {
      if (isFocus.current) makeMessageAsRead();
    },
  });

  useEffect(() => {
    if (chatContainerRef.current && !isLoadMore.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    } else if (isLoadMore.current) {
      isLoadMore.current = false;
    }
  }, [messages]);

  if (isLoading) return <ChatSkeletons />;
  if (isError) throw new Error(constants.ERRORS.UNEXPECTED_ERROR);

  return (
    <div className="h-full overflow-hidden bg-[#f7f2ff]">
      <Header
        seen
        back
        path={path}
        isCollapsed
        user={receiver}
        color="primary"
        className="md:h-16 md:!p-4"
        online={receiver.is_active && receiver.is_online}
      />

      <div
        ref={chatContainerRef}
        className={classNames({
          "no-scrollbar bg-[#f7f2ff] px-2": true,
          "h-[65vh] md:h-[68vh]": !isMyEngage,
          "h-[85vh] md:h-[80vh]": isMyEngage,
        })}
      >
        <div className="chat-ui no-scrollbar flex h-full w-full  flex-col-reverse overflow-scroll pb-8">
          {messages.map((message, index) => {
            const { prevMessage, nextMessage } = calculatePrevAndNext(
              messages,
              index,
            );

            return (
              <Bubble
                color="primary"
                defaultPicture=""
                hideRemovedMessage={hideRemovedMessage}
                key={message._id}
                message={message}
                next={nextMessage}
                pathToProfile={path}
                previous={prevMessage}
                receiver={receiver}
                removeMessageFromAll={removeMessageFromAll}
                removeMessageFromMe={hidingMessage}
                removeTargetMessage={hidingMessage}
                reportMessage={reportingMessage}
                sender={user}
                showBubbleActions
                showMessageStatus
              />
            );
          })}

          {threadQuery.hasNextPage && (
            <span
              onClick={() => {
                isLoadMore.current = true;
                threadQuery.fetchNextPage();
              }}
              className={classNames({
                "badge badge-sm my-2  cursor-pointer self-center bg-gradient-to-r from-primary to-primary-focus p-4 text-white":
                  true,
                ["loading"]: threadQuery.isFetching,
              })}
            >
              {constants.LOAD_MORE}
            </span>
          )}

          <Input
            color="primary"
            alphabeticNumber
            className={classNames({
              "bottom-[68px] self-center !bg-transparent md:bottom-7 md:!w-[40vw]":
                true,
              "bottom-[68px]": !isMyEngage,
              "bottom-2": isMyEngage,
            })}
            onFocus={makeMessageAsRead}
            onBlur={() => (isFocus.current = false)}
            onSend={onSend}
          />
        </div>
      </div>
    </div>
  );
};

export default ThreadPage;
