import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useLastUsers from "queries/lastUsers";
import { useEffect, useRef, useState } from "react";
import UserItem from "types/UserItem";
import FilterList from "./FilterList";

const LastUsersPage = () => {
  const { lastUsers, isLoading, isError, error, ...actions } = useLastUsers();
  const [users, setUsers] = useState<UserItem[]>(lastUsers);
  const initData = useRef(lastUsers);

  const onFilter = (list: UserItem[] | string) => {
    if (list === "reset") setUsers(initData.current);
    else if (typeof list !== "string") setUsers([...list]);
  };

  const Filter = (
    <div className="flex w-full justify-end">
      <FilterList onSort={onFilter} list={initData.current} />
    </div>
  );

  useEffect(() => {
    if (!initData.current) initData.current = lastUsers;
    setUsers(lastUsers);
  }, [lastUsers]);

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);

  return (
    <div>
      <List
        infinite
        data={users}
        FirstRow={Filter}
        hasNextPage={actions.hasNextPage}
        next={actions.fetchNextPage}
        title={constants.NEW_MEMBERS}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              username={item.name}
              country={item.country}
              guardian={item.is_guardian}
              image={item.profile_picture}
              online={item.is_active && item.is_online}
              status={item.relationship_status}
              to={`/new/profile/${item.uid}`}
              state={{ ids: lastUsers.map((e) => e.uid) }}
            />
          );
        }}
      />
    </div>
  );
};

export default LastUsersPage;
