import guardians from "api/guardians";
import EmptyPage from "components/Empty";
import List from "components/lists/List";
import SearchSkeletons from "components/theme/skeleton/search";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import useGuardians from "queries/guardians";
import { useState } from "react";
import { BiSolidUser, BiUser } from "react-icons/bi";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";
import CreateUser from "./CreateUser";
import ListItem from "./ListItem";

const GuardianPage = () => {
  const { users, isLoading, error, isError } = useGuardians();
  const [showModal, setShowModal] = useState(false);
  const toast = useToast();

  async function loginWith(id: string) {
    const credentials = await guardians.getUserCredentials(id);
    if (credentials.ok) {
      const { refresh_token, token } = credentials.data!;
      storage.remove("guardian");
      storage.storeToken(token, refresh_token);
      refreshPage("/");
    } else toast.error(constants.ERRORS.UNEXPECTED_ERROR);
  }

  if (isError) throw new Error(error?.name);

  return (
    <div className="h-full md:h-[70vh]">
      {showModal && <CreateUser setVisibility={setShowModal} />}

      <div
        onClick={() => setShowModal(true)}
        className="btn btn-primary btn-outline btn-sm absolute left-5 top-12 flex gap-2 rounded-full"
      >
        <BiUser />
        {constants.ADD_MEMBER}
      </div>

      {isLoading ? (
        <SearchSkeletons />
      ) : !users.length ? (
        <EmptyPage content={constants.EMPTY_USERS} Icon={BiSolidUser} />
      ) : (
        <List
          wrapped
          horizontal
          data={users}
          containerClassName="pb-32"
          keyExtractor={(item) => item.uid}
          renderItem={(item) => {
            return (
              <ListItem
                username={item.name}
                image={item.profile_picture}
                login={() => loginWith(item._id)}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default GuardianPage;
