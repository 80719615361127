import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useMatched from "queries/matched";
import { LuUsers } from "react-icons/lu";

const MatchedPage = () => {
  const { matched, isLoading, isError, error, ...actions } = useMatched();

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!matched.length)
    return <EmptyPage content={constants.EMPTY_MATCHED_LIST} Icon={LuUsers} />;

  return (
    <div>
      <List
        infinite
        data={matched}
        title={constants.MATCHED_USERS}
        hasNextPage={actions.hasNextPage}
        next={actions.fetchNextPage}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              username={item.name}
              country={item.country}
              guardian={item.is_guardian}
              image={item.profile_picture}
              matchedPercent={item.match_percent}
              status={item.relationship_status}
              state={{ ids: matched.map((e) => e.uid) }}
              to={`profile/${item.uid}`}
            />
          );
        }}
      />
    </div>
  );
};

export default MatchedPage;
