import TimeAgo from "components/TimeAgo";
import constants from "configs/constants";

const ProfileStatus = ({ online = false, last_seen = "" }) => {
  const bColor = online ? "badge-success" : "badge-primary";
  const color = online ? "text-success" : "text-primary";
  return (
    <>
      <div className={`text-center text-base font-bold ${color}`}>
        <div>
          {!online ? (
            <div className="flex flex-col items-center gap-x-2">
              <span className="md:text-xl">{`${constants.LAST_VISIT}`}</span>
              <TimeAgo
                className="!text-accent max-md:text-xs"
                time={last_seen}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center gap-x-2 text-center font-bold md:text-xl">
              {online && <div className={`badge badge-xs ${bColor}`} />}
              <span>{`${constants.ONLINE_NOW}`}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileStatus;
