import { useQuery } from "@tanstack/react-query";
import users from "api/users";
import ms from "ms";
import queryClient from "./queryClient";
import ErrorBoundary from "utils/ErrorBoundary";

export const getUserById = (uid: string) =>
  queryClient.fetchQuery({
    queryKey: ["user", uid],
    queryFn: () => users.getById(uid).then((res) => res.data),
    staleTime: ms("30m"),
  });

const useUsers = ({ guest, uid }: { guest?: boolean; uid: string }) => {
  const query = useQuery({
    queryKey: ["user", uid],
    queryFn: ErrorBoundary(() =>
      (guest ? users.getByIdForGuest : users.getById)(uid),
    ),
    staleTime: ms("30m"),
  });

  return {
    user: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useUsers;
