import { BiBuildingHouse, BiFace, BiGroup, BiWorld } from "react-icons/bi";
import { CgUserList } from "react-icons/cg";
import {
  FaGraduationCap,
  FaMosque,
  FaUserGraduate,
  FaWeight,
} from "react-icons/fa";
import {
  FaHandsHoldingChild,
  FaLocationDot,
  FaUsersBetweenLines,
} from "react-icons/fa6";
import {
  GiBodyHeight,
  GiDiamondRing,
  GiHealthPotion,
  GiTakeMyMoney,
} from "react-icons/gi";
import { LuScanFace } from "react-icons/lu";

export const labels = [
  {
    name: "nationality",
    label: "الجنسية",
    Icon: () => <BiWorld className="text-lg text-[dodgerblue]" />,
  },
  {
    name: "country",
    label: "الإقامة",
    Icon: () => <FaLocationDot className="text-lg text-primary" />,
  },
  {
    name: "city",
    label: "المدينة",
    Icon: () => <BiBuildingHouse className="text-lg text-[gold]" />,
  },
  {
    name: "relationship_status",
    label: "الحالة الاجتماعية",
    Icon: () => <GiDiamondRing className="text-lg text-accent" />,
  },
  {
    name: "origin",
    label: "الأصل",
    Icon: () => <BiGroup className="text-lg text-cyan-500" />,
  },
  {
    name: "clan",
    label: "القبيلة",
    Icon: () => <FaUsersBetweenLines className="text-lg text-secondary" />,
  },
  {
    name: "children",
    label: "عدد الأطفال",
    Icon: () => <FaHandsHoldingChild className="text-lg text-violet-700" />,
  },
  {
    name: "education",
    label: "المستوى التعليمي",
    Icon: () => <FaGraduationCap className="text-lg text-pink-500" />,
  },
  {
    name: "beauty",
    label: "الجمال",
    Icon: () => <BiFace className="text-lg text-rose-600" />,
  },
  {
    name: "occupation",
    label: "طبيعة العمل",
    Icon: () => <FaUserGraduate className="text-lg text-info" />,
  },
  {
    name: "financial_status",
    label: "الحالة المادية",
    Icon: () => <GiTakeMyMoney className="text-lg text-green-500" />,
  },
  {
    name: "prayers",
    label: "الصلاة",
    Icon: () => <FaMosque className="text-lg text-warning" />,
  },
  {
    name: "health",
    label: "الصحة",
    Icon: () => <GiHealthPotion className="text-lg text-red-400" />,
  },
  {
    name: "skin_color",
    label: "لون البشرة",
    Icon: () => <LuScanFace className="text-lg text-blue-300" />,
  },
  {
    name: "age",
    label: "العمر",
    Icon: () => <CgUserList className="text-lg text-lime-300" />,
  },
  {
    name: "height",
    label: "الطول",
    Icon: () => <GiBodyHeight className="text-lg text-amber-500" />,
  },
  {
    name: "weight",
    label: "الوزن",
    Icon: () => <FaWeight className="text-lg text-teal-400" />,
  },
];
