import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useForme from "queries/forme";
import { LuUsers } from "react-icons/lu";

const FormePage = () => {
  const { forme, isLoading, isError, error, ...actions } = useForme();

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!forme.length)
    return <EmptyPage content={constants.EMPTY_FORME_LIST} Icon={LuUsers} />;

  return (
    <div>
      <List
        infinite
        data={forme}
        hasNextPage={actions.hasNextPage}
        next={actions.fetchNextPage}
        title={constants.FORME_USERS}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              username={item.name}
              country={item.country}
              guardian={item.is_guardian}
              image={item.profile_picture}
              matchedPercent={item.match_percent}
              status={item.relationship_status}
              to={`profile/${item.uid}`}
              state={{ ids: forme.map((e) => e.uid) }}
            />
          );
        }}
      />
    </div>
  );
};

export default FormePage;
