interface Props {
  title: string;
  checked: boolean;
  toggle: () => void;
  error?: boolean;
  size?: "checkbox-xs" | "checkbox-sm" | "checkbox-md" | "checkbox-lg";
  color?:
    | "checkbox-neutral"
    | "checkbox-primary"
    | "checkbox-secondary"
    | "checkbox-accent"
    | "checkbox-info"
    | "checkbox-success"
    | "checkbox-warning"
    | "checkbox-error";
  labelClassName?: string;
}

const CheckBox = ({
  title,
  error,
  size = "checkbox-md",
  color = "checkbox-accent",
  checked,
  toggle,
  labelClassName,
}: Props) => {
  return (
    <div className="form-control w-fit">
      <label className="label cursor-pointer items-start gap-x-3">
        <input
          type="checkbox"
          checked={checked}
          onChange={toggle}
          className={`checkbox ${error && "checkbox-error"} ${size} ${color}`}
        />
        <span className={`label-text ${labelClassName}`}>{title}</span>
      </label>
    </div>
  );
};

export default CheckBox;
