import List from "components/lists/List";
import RowListItem from "components/lists/RowListItem";
import UserListItem from "components/lists/UserListItem";
import constants from "configs/constants";
import Ads from "pages/Ads";
import useLastUsers from "queries/lastUsers";
import useProposalUsers from "queries/proposalUsers";
import { Link } from "react-router-dom";
import UserItem from "types/UserItem";

const HomePage = () => {
  const members = useLastUsers();
  const proposals = useProposalUsers();

  return (
    <div>
      {/* proposal members */}
      {!proposals.isError && (
        <div>
          {!proposals.isLoading && !!proposals.proposalUsers.length && (
            <div
              className={`item-center -mb-7 mt-3 flex flex-row justify-between px-2 pr-5`}
            >
              <p className="text-xl font-bold">{constants.PROPOSAL_MEMBERS}</p>
              <Link className="text-error underline" to={`users/proposal`}>
                {constants.MORE}
              </Link>
            </div>
          )}
          <List
            horizontal
            data={
              (proposals.proposalUsers?.length
                ? proposals.proposalUsers
                : undefined) as UserItem[]
            }
            loading={proposals.isLoading}
            keyExtractor={(item) => item.uid}
            renderItem={(item) => {
              return (
                <RowListItem
                  age={item.age}
                  city={item.city}
                  country={item.country}
                  guardian={item.is_guardian}
                  image={item.profile_picture}
                  online={item.is_active! && item.is_online!}
                  status={item.relationship_status}
                  to={`profile/${item.uid}`}
                  username={item.name}
                  matchedPercent={item.match_percent}
                />
              );
            }}
          />
        </div>
      )}

      <Ads />

      {/* new members */}
      {!members.isError && (
        <div>
          {!members.isLoading && !!members.lastUsers.length && (
            <div
              className={`item-center -mb-4 mt-10 flex flex-row justify-between px-2 pr-5`}
            >
              <p className="text-xl font-bold">{constants.LAST_MEMBERS}</p>
              <Link className="text-error underline" to="users/last">
                {constants.MORE}
              </Link>
            </div>
          )}
          <List
            loading={members.isLoading}
            data={
              (members.lastUsers?.length
                ? members.lastUsers
                : undefined) as UserItem[]
            }
            keyExtractor={(item) => item.uid}
            renderItem={(item) => {
              return (
                <UserListItem
                  age={item.age}
                  city={item.city}
                  country={item.country}
                  guardian={item.is_guardian}
                  image={item.profile_picture}
                  online={item.is_active && item.is_online}
                  status={item.relationship_status}
                  to={`profile/${item.uid}`}
                  username={item.name}
                />
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HomePage;
