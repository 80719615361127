import constants from "configs/constants";
import { FC } from "react";

export type TProfileDisplay = "history" | "items";

interface Props {
  tab: TProfileDisplay;
  setTab: (v: TProfileDisplay) => void;
}

const Tabs: FC<Props> = ({ tab, setTab }) => {
  return (
    <div className="tabs-xs sm:tabs-sm md:tabs-md tabs-boxed  w-fit !rounded-3xl border-[0.1rem] bg-white">
      <div
        onClick={() => setTab("history")}
        className={`tab ${
          tab === "history" &&
          "tab-active !rounded-2xl font-semibold !text-white"
        }`}
      >
        {constants.STORY}
      </div>
      <div
        onClick={() => setTab("items")}
        className={`tab hover:text-gray-500 ${
          tab === "items" &&
          "tab-active !rounded-2xl  font-semibold !text-white"
        }`}
      >
        {constants.MENU}
      </div>
    </div>
  );
};

export default Tabs;
