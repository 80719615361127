import { ApiResponse } from "apisauce";
import constants from "configs/constants";

function ErrorBoundary<T>(fn: () => Promise<ApiResponse<T, T>>) {
  return async () => {
    const { ok, data } = await fn();
    if (ok) return data!;
    else {
      const err = (data as Error)?.name || constants.ERRORS.UNEXPECTED_ERROR;
      throw new Error(err);
    }
  };
}

export default ErrorBoundary;
