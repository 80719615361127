import AlertProvider from "components/core/Alert";
import Badge from "components/core/Badge";
import useAuth from "hooks/useAuth";
import SupportThread from "pages/support/Thread";
import useSupport from "queries/support";
import useUser from "queries/user";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useThread from "stores/thread";
import Navbar from "../../Navbar";

const ROUTES = [
  { key: "guardians", title: "الاعضاء الموكل عنهم", path: "/guardian" },
  { key: "supports", title: "الدعم الفني", path: "/guardian/support" },
];

const GuardianContainer = () => {
  const { isAuth, isGuardian } = useAuth();
  const { chats } = useSupport();
  const { removed } = useUser();

  const th = useThread((s) => s.thread);
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const badge =
    chats && !!chats.length
      ? chats.filter(
          (msg) =>
            !msg.seen && !msg.messages[msg.messages.length - 1].is_from_user,
        ).length
      : 0;

  if (!isAuth) return <Navigate to="/login" />;
  else if (!isGuardian) return <Navigate to="/" />;
  else if (removed) return <Navigate to="/pending" />;

  return (
    <AlertProvider>
      <div className="h-screen overflow-hidden">
        {/* navbar */}
        <Navbar showIcons={false} />

        <div className="relative my-4 grid grid-cols-1 grid-rows-1 justify-center gap-2 md:grid-cols-[100%]">
          {/* content */}
          <div className="flex items-center justify-center">
            <div className="tabs-boxed tabs gap-x-5 !rounded-3xl border-[0.1rem] bg-white">
              {ROUTES.map(({ path, key, title }) => {
                return (
                  <div
                    key={key}
                    className={`tab !rounded-3xl text-xs hover:text-gray-500 md:text-sm ${
                      path === pathname &&
                      "tab-active font-semibold !text-white"
                    }`}
                    onClick={() => navigate(path)}
                  >
                    {title}
                    {key === "supports" && !!badge ? (
                      <Badge className="mr-2" value={badge} />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="no-scrollbar mx-auto h-screen w-screen self-center overflow-auto md:h-[80vh] md:w-[70%] lg:w-[60%]">
            {th.visible && th.fromSupport && <SupportThread />}

            <Outlet />
          </div>
        </div>
      </div>
    </AlertProvider>
  );
};

export default GuardianContainer;
