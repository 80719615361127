import * as Yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import constants from "configs/constants";

function testPhoneNumber(value: string | undefined) {
  if (!value) return false;

  try {
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber && phoneNumber.isValid()) return true;
    else return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

const token = Yup.object({
  token: Yup.string().required(constants.TOKEN_REQUIRED),
});

const phone = Yup.object({
  phone: Yup.string()
    .required(constants.PHONE_REQUIRED)
    .test("v-phone", constants.PHONE_MATCH, testPhoneNumber),
});

const name = Yup.object({
  name: Yup.string()
    .required(constants.NAME_REQUIRED)
    .min(3, constants.NAME_LENGTH)
    .max(20, constants.NAME_LENGTH),
});

const reset = Yup.object({
  email: Yup.string()
    .email(constants.EMAIL_MATCH)
    .required(constants.EMAIL_REQUIRED),

  password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PASSWORD_REQUIRED),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], constants.CONFIRM_PASSWORD_MATCH)
    .required(constants.CONFIRM_PASSWORD_REQUIRED),
});

const changePassword = Yup.object({
  current_password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PRE_PASSWORD_REQUIRED),

  new_password: Yup.string()
    .required(constants.NEW_PASSWORD_REQUIRED)
    .min(8, constants.PASSWORD_LENGTH)
    .notOneOf([Yup.ref("current_password")], constants.PREV_NEW_PASSWORDs),

  confirm_new_password: Yup.string()
    .required(constants.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([Yup.ref("confirm_new_password")], constants.CONFIRM_PASSWORD_MATCH),
});

const updateProfile = Yup.object().shape({
  email: Yup.string()
    .email(constants.EMAIL_MATCH)
    .required(constants.EMAIL_REQUIRED),

  age: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === "" ? undefined : value,
    )
    .required(constants.AGE_REQUIRED)
    .min(18, constants.AGE_LENGTH)
    .max(53, constants.AGE_LENGTH),
});

const unAccepted = (isGuardian: boolean, props: string[]) => {
  if (!props.length) return;
  const allProps = {
    name: isGuardian
      ? Yup.string()
          .required(constants.FOURTH_NAME_REQUIRED)
          .min(10, constants.FOURTH_NAME_LENGTH)
      : Yup.string()
          .required(constants.NAME_REQUIRED)
          .min(3, constants.NAME_LENGTH)
          .max(20, constants.NAME_LENGTH),

    national_number: Yup.string()
      .required(constants.NATIONAL_NUM_REQUIRED)
      .min(10, constants.NATIONAL_NUM_REQUIRED_LENGTH),

    birthday: Yup.string().required(constants.BIRTHDAY_REQUIRED),

    bio: Yup.string().required(constants.DESCRIPTION_REQUIRED),

    description: Yup.string().required(constants.DESCRIPTION_REQUIRED),
  };

  const shape = props.map((e) => ({
    [e]: allProps[e as keyof typeof allProps],
  }));

  return Yup.object().shape(Object.assign({}, ...shape));
};

const guardianRegister = Yup.object().shape({
  name: Yup.string()
    .required(constants.FOURTH_NAME_REQUIRED)
    .min(10, constants.FOURTH_NAME_LENGTH),

  email: Yup.string()
    .email(constants.EMAIL_MATCH)
    .required(constants.EMAIL_REQUIRED),

  phone: Yup.string()
    .required(constants.PHONE_REQUIRED)
    .test("v-phone", constants.PHONE_MATCH, testPhoneNumber),

  national_number: Yup.string()
    .required(constants.NATIONAL_NUM_REQUIRED)
    .min(10, constants.NATIONAL_NUM_REQUIRED_LENGTH),

  birthday: Yup.string().required(constants.BIRTHDAY_REQUIRED),

  password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PASSWORD_REQUIRED),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], constants.CONFIRM_PASSWORD_MATCH)
    .required(constants.CONFIRM_PASSWORD_REQUIRED),
});

const register = Yup.object().shape({
  name: Yup.string()
    .required(constants.NAME_REQUIRED)
    .min(3, constants.NAME_LENGTH)
    .max(20, constants.NAME_LENGTH),

  email: Yup.string()
    .email(constants.EMAIL_MATCH)
    .required(constants.EMAIL_REQUIRED),

  phone: Yup.string()
    .required(constants.PHONE_REQUIRED)
    .test("v-phone", constants.PHONE_MATCH, testPhoneNumber),

  password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PASSWORD_REQUIRED),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], constants.CONFIRM_PASSWORD_MATCH)
    .required(constants.CONFIRM_PASSWORD_REQUIRED),
});

const loginWithPhone = Yup.object().shape({
  phone: Yup.string()
    .required(constants.PHONE_REQUIRED)
    .test("v-phone", constants.PHONE_MATCH, testPhoneNumber),
  password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PASSWORD_REQUIRED),
});

const loginWithUsername = Yup.object().shape({
  name: Yup.string()
    .required(constants.NAME_REQUIRED)
    .min(3, constants.NAME_LENGTH)
    .max(20, constants.NAME_LENGTH),
  password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PASSWORD_REQUIRED),
});

const loginWithEmail = Yup.object().shape({
  email: Yup.string()
    .email(constants.EMAIL_MATCH)
    .required(constants.EMAIL_REQUIRED),
  password: Yup.string()
    .min(8, constants.PASSWORD_LENGTH)
    .required(constants.PASSWORD_REQUIRED),
});

const email = Yup.object().shape({
  email: Yup.string()
    .email(constants.EMAIL_MATCH)
    .required(constants.EMAIL_REQUIRED),
});

const youngInfo = Yup.object().shape({
  name: Yup.string()
    .required(constants.NAME_REQUIRED)
    .min(3, constants.NAME_LENGTH),

  national_number: Yup.string()
    .required(constants.NATIONAL_NUM_REQUIRED)
    .min(10, constants.NATIONAL_NUM_REQUIRED_LENGTH),

  birthday: Yup.string().required(constants.BIRTHDAY_REQUIRED),

  phone: Yup.string()
    .required(constants.PHONE_REQUIRED)
    .test("v-phone", constants.PHONE_MATCH, testPhoneNumber),
});

const guardianInfo = Yup.object().shape({
  girl_name: Yup.string()
    .required(constants.NAME_REQUIRED)
    .min(3, constants.NAME_LENGTH),

  guardian_name: Yup.string()
    .required(constants.GUARDIAN_NAME_REQUIRED)
    .min(3, constants.GUARDIAN_NAME_LENGTH),

  suggestion_how_to_find: Yup.string().required(
    constants.FIND_SUGGESTION_REQUIRED,
  ),

  guardian_phone_number: Yup.string()
    .required(constants.PHONE_REQUIRED)
    .test("v-phone", constants.PHONE_MATCH, testPhoneNumber),
});

export default {
  email,
  name,
  reset,
  changePassword,
  loginWithEmail,
  loginWithUsername,
  phone,
  register,
  unAccepted,
  guardianRegister,
  loginWithPhone,
  updateProfile,
  youngInfo,
  guardianInfo,
  token,
};
