import NavIcons from "components/NavIcons";
import constants from "configs/constants";
import { AiOutlineMessage } from "react-icons/ai";
import { BiLike } from "react-icons/bi";
import { CgEye } from "react-icons/cg";
import { GiDiamondRing } from "react-icons/gi";

export const items = [
  {
    label: "visits",
    title: constants.BAR_VISITS,
    path: "/visits",
    Icon: CgEye,
  },
  {
    label: "engage",
    path: "/engagements",
    title: constants.BAR_ENGAGE,
    Icon: GiDiamondRing,
  },
  {
    label: "likes",
    path: "/likes",
    title: constants.BAR_LIKES,
    Icon: BiLike,
  },
  {
    label: "chats",
    path: "/chats",
    title: constants.BAR_MESSAGES,
    Icon: AiOutlineMessage,
  },
];

const BottomBar = () => {
  return (
    <div className="btm-nav z-30 border-t-[0.1rem] border-gray-300">
      <NavIcons
        className="justify-evenly"
        tooltipPosition="tooltip-top"
        items={items}
      />
    </div>
  );
};

export default BottomBar;
