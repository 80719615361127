import classNames from "classnames";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import ProfileSkeleton from "components/theme/skeleton/profile";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import Actions from "pages/profile/components/Actions";
import ProfileAvatar from "pages/profile/components/ProfileAvatar";
import ProfileLocation from "pages/profile/components/ProfileLocation";
import ProfileStatus from "pages/profile/components/ProfileStatus";
import Tabs, { TProfileDisplay } from "pages/profile/components/Tabs";
import History from "pages/profile/History";
import Info from "pages/profile/Info";
import useUsers from "queries/users";
import { useState } from "react";
import { FaClipboard } from "react-icons/fa";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import User from "types/User";

const GuestProfilePage = () => {
  const { uid, section } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isPaginate = state?.ids;
  const current = state?.ids && state?.ids.findIndex((e: string) => e === uid);
  const isPrevious = state?.ids && state.ids[current - 1];
  const isNext = state?.ids && state.ids[current + 1];
  const toast = useToast();

  const [displayAs, setDisplayAs] = useState<TProfileDisplay>("history");
  const [showNotify, setShowNotify] = useState(false);
  const { user, isError, isLoading, error } = useUsers({
    guest: true,
    uid: uid!,
  });

  if (isError) throw new Error(error?.name);
  if (isLoading) {
    return (
      <div className="mx-auto lg:!max-w-[60vw]">
        <ProfileSkeleton />
      </div>
    );
  }

  const { profile, preferences, account } = user as User;
  const isFemale = account.gender === "female";
  const opGender = isFemale ? "male" : "female";

  const online = account.is_active || account.is_online;

  const empty = () => setShowNotify(true);

  const asyncEmpty = async () => setShowNotify(true);

  const onNext = () => {
    navigate(`/${section}/profile/${state.ids[current + 1]}`, {
      state: { ids: state.ids },
    });
  };

  const onPrevious = () => {
    navigate(`/${section}/profile/${state.ids[current - 1]}`, {
      state: { ids: state.ids },
    });
  };

  const ActionsButton = ({ className = "", iconClassName = "" }) => {
    return (
      <div
        className={`flex h-fit gap-x-10 justify-self-center max-md:order-1 ${className}`}
      >
        <Actions
          iconClassName={iconClassName}
          checkUser={asyncEmpty}
          contactLoading={false}
          isBlocked={false}
          isFavorite={false}
          isFemale={isFemale}
          isMyProfile={false}
          makeAsFavorite={asyncEmpty}
          onContact={asyncEmpty}
          sendEngage={asyncEmpty}
          setShowReport={empty}
          toggleBlock={asyncEmpty}
        />
      </div>
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(account.uid);
    toast.success(constants.TOAST.COPY_UID);
  };

  return (
    <div
      className={classNames({
        "relative mx-auto w-full pt-5 lg:!max-w-[70vw]": true,
        "overflow-hidden": !isPaginate,
      })}
    >
      {user.account.is_guardian && (
        <div className="absolute left-0 -ml-14 w-48 -rotate-45 bg-primary py-2 text-center text-lg font-bold text-base-100">
          {constants.GUARDIAN_USER}
        </div>
      )}

      {isPaginate && (
        <div className="sticky top-[40%] z-50 flex items-center justify-between px-2">
          <div
            onClick={onPrevious}
            className={`btn btn-circle p-0 text-4xl text-primary ${
              !isPrevious && "btn-disabled"
            }`}
          >
            <FaCircleArrowRight />
          </div>

          <div
            onClick={onNext}
            className={`btn btn-circle p-0 text-4xl text-primary ${
              !isNext && "btn-disabled"
            }`}
          >
            <FaCircleArrowLeft />
          </div>
        </div>
      )}

      <div className="grid-cols-[60%, 40%] mb-5 grid grid-rows-[2fr,1fr] gap-3 md:mb-14 md:grid-cols-3 md:grid-rows-[60%,20%,15%] md:gap-6">
        <div className="flex flex-col justify-center gap-x-3  max-md:gap-y-4">
          <div className="flex flex-col items-center gap-y-2 truncate font-bold text-primary max-md:gap-x-2">
            <span className="md:text-xl">{`${constants.UID}`}</span>
            <span className="flex items-center justify-center gap-x-2 truncate text-accent max-md:text-xs">
              {`${account.uid}`}
              <FaClipboard
                onClick={copyToClipboard}
                className="cursor-pointer text-xs hover:text-accent-focus"
              />
            </span>
          </div>
          <div className="md:hidden">
            <ProfileStatus online={online} last_seen={account.last_seen} />
          </div>
        </div>
        {/*  Profile avatar */}
        <div className="flex h-fit items-center justify-center max-md:-order-1 md:px-12">
          <ProfileAvatar
            online={online}
            gender={opGender}
            account={account}
            profile={profile}
          />
        </div>

        {/* status or (on/off)line, last seen */}
        <div className="hidden items-center justify-center gap-x-3 md:flex">
          <ProfileStatus online={online} last_seen={account.last_seen} />
        </div>

        {/* actions  */}
        <div className="h-fit gap-x-10 justify-self-center max-md:col-span-2 max-md:self-center md:col-span-3">
          <ActionsButton />
        </div>

        {/* country and city */}
        <div className="flex h-fit items-start justify-self-center text-lg font-bold max-md:col-span-2 max-md:self-center md:col-span-3">
          <ProfileLocation profile={profile} />
        </div>
      </div>

      {/*  tabs info */}
      <div className="mb-36 flex flex-col md:mb-8">
        <div className="flex justify-end px-2 md:mt-0">
          <Tabs tab={displayAs} setTab={setDisplayAs} />
        </div>
        {displayAs === "history" ? (
          <History
            gender={account.gender}
            profile={{ ...profile, age: `${account.age}` }}
            preferences={preferences}
          />
        ) : (
          <>
            <div>
              <Info
                isProfile
                user={{ ...profile, age: `${account.age}` }}
                titleOne={constants.MY_INFO}
                titleTow={constants.MY_INFO_IN_DETAILS}
              />
            </div>

            <div className="mt-6">
              <Info
                user={preferences}
                titleOne={constants.MY_CONDITIONS}
                titleTow={constants.MY_CONDITIONS_IN_DETAILS}
              />
            </div>
          </>
        )}
      </div>

      <Modal
        className="!max-w-md"
        visible={!!showNotify}
        setVisibility={() => setShowNotify(false)}
      >
        <div className="flex flex-col items-center gap-5 pt-5">
          <p className="mb-5 text-center font-medium">
            للتواصل مع هذا العضو يرجى تسجيل عضوية
          </p>
          <Button
            color="btn-primary"
            outline
            onClick={() => navigate("/register")}
          >
            {constants.SING_FOR_FREE}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default GuestProfilePage;
