import users from "api/users";
import constants from "configs/constants";
import events from "configs/events";
import { navbarSetting } from "data/navbar";
import useAlert from "hooks/useAlert";
import useAuth from "hooks/useAuth";
import useSocket from "hooks/useSocket";
import useUser from "queries/user";
import useWallet from "queries/wallets";
import { useNavigate } from "react-router-dom";
import userLogout from "utils/userLogout";
import Avatar from "./Avatar";
import TimeAgo from "./TimeAgo";
import Switch from "./core/Switch";
import { Tooltip } from "./theme/Tooltip";

export const ProfileIcon = () => {
  const { isGuardian } = useAuth();
  const { user, removed, updateUser } = useUser();
  const { wallet } = useWallet();
  const isSubscribe = wallet?.type === "paid";
  const { socket } = useSocket();

  const navigate = useNavigate();
  const alert = useAlert();

  function handleSetting(path: string) {
    if (path === "/logout") return userLogout();

    if (removed) return;
    if (path === "/account/permanent-deletion") return permanentDeletion();
    else if (path === "/profile") {
      const { profile, preferences, ...account } = user;
      return navigate(path + `/${user.uid}`, {
        state: { user: { profile, preferences, account }, preview_me: true },
      });
    } else if (path === "/edit/password" && isGuardian)
      return navigate(`/guardian${path}`);

    navigate(path);
  }

  function permanentDeletion() {
    alert({
      title: constants.PERMANENT_DELETION,
      content: constants.ALERT.REMOVE_ACCOUNT,
      onConfirm: async () => {
        await users.permanentDeletion(user.uid);
        setTimeout(() => userLogout(), 250);
      },
    });
  }

  function toggleUserStatus() {
    const status = !user.is_active;
    socket?.emit(events.CHANGE_STATUS, { status });
    updateUser({ is_active: status });
  }

  return (
    <div className="dropdown dropdown-left">
      <label tabIndex={0} className="hover:cursor-pointer">
        <Tooltip text={isSubscribe ? constants.SUBSCRIBER : constants.PROFILE}>
          <div className="max-md:mt-1">
            <Avatar
              size="sm"
              subscriber={isSubscribe}
              online={user.is_active}
              offline={!user.is_active}
              src={user.profile_picture}
            />
          </div>
        </Tooltip>
      </label>

      <ul
        tabIndex={0}
        className="menu dropdown-content rounded-box menu-md z-[1] mt-6 w-48 border-2 border-base-200 bg-base-100 p-1 shadow-lg"
      >
        {!removed && !isGuardian && (
          <div className="mb-4 flex flex-col gap-y-2 self-start px-2">
            <div className="text-sm font-bold">
              <span>{`${constants.WELCOME}، `}</span>
              <span className="text-primary">{`${user.name}`}</span>
            </div>

            <div className="line-clamp-1 text-sm font-bold">
              <span>{`${constants.LAST_VISIT}: `}</span>
              <TimeAgo time={user.last_seen} />
            </div>

            <div className="line-clamp-1 text-sm font-bold">
              <span>{`${constants.ENGAGE_COUNTER}: `}</span>
              <span className="text-primary">{`${wallet?.balance} ${constants.ENGAGE}`}</span>
            </div>

            <Switch
              className="mt-1 w-fit"
              horizontal
              size="toggle-sm"
              title={
                user.is_active ? constants.CONNECTED : constants.UNCONNECTED
              }
              checked={user.is_active}
              toggle={toggleUserStatus}
            />
          </div>
        )}

        {navbarSetting.map((item, index) => {
          if (isGuardian && item.path.includes("permanent-deletion"))
            return null;

          const isCritical = index > 1;
          return (
            <div key={index.toString()}>
              <li>
                <span
                  onClick={() => handleSetting(item.path)}
                  className={`flex flex-row justify-start px-2 py-2 ${
                    isCritical && "font-semibold text-red-600"
                  }`}
                >
                  <item.Icon />
                  {item.name}
                </span>
              </li>
              {index === 1 && <div className="divider mx-6 my-0"></div>}
            </div>
          );
        })}
      </ul>
    </div>
  );
};
