import { navbarIcons } from "data/navbar";
import useBadges from "queries/badges";
import { IconType } from "react-icons/lib";
import { NavLink } from "react-router-dom";
import Badge from "./core/Badge";
import { Tooltip } from "./theme/Tooltip";

interface Props {
  className?: string;
  tooltipPosition?:
    | "tooltip-bottom"
    | "tooltip-top"
    | "tooltip-left"
    | "tooltip-right";
  items?: {
    label: string;
    title: string;
    path: string;
    Icon: IconType;
  }[];
}

const NavIcons = ({
  className = "",
  tooltipPosition,
  items = navbarIcons,
}: Props) => {
  const { badges } = useBadges();

  return (
    <nav className={`flex h-full w-full flex-row justify-center ${className}`}>
      {items.map(({ path, Icon, title, label }, index) => {
        const badge = (badges || {})[label as keyof typeof badges];
        return (
          <NavLink
            to={path}
            key={index.toString()}
            className={({ isActive }) =>
              `flex px-6 ${
                isActive && "border-b-2 border-primary text-primary"
              } h-full items-center`
            }
          >
            <div className="indicator indicator-end">
              <Tooltip position={tooltipPosition} text={title}>
                <Icon className="icon" />
              </Tooltip>
              {!!badge && <Badge value={badge} />}
            </div>
          </NavLink>
        );
      })}
    </nav>
  );
};

export default NavIcons;
