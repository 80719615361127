import List from "components/lists/List";
import { Tooltip } from "components/theme/Tooltip";
import constants from "configs/constants";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import { labels } from "./utils";

interface Props {
  user: TProfile | TPreference;
  titleOne: string;
  titleTow: string;
  isProfile?: boolean;
}

interface IList {
  isProfile?: boolean;
  info: TPreference | TProfile;
}

const PROP_WITH_TOW_VALUES = ["age", "weight", "height"];
const REDUNDANT_VALUES = [
  "age",
  "nationality",
  "country",
  "city",
  "relationship_status",
];

const arYesOrNo = (value: string | boolean) => {
  return value === true ? "نعم" : value === false ? "ﻻ" : value;
};

type Item = { label: any; name: any; Icon: any; index: any };

const _renderItem = (
  info: TProfile | TPreference,
  { label, name, Icon, index }: Item,
) => {
  type Key = keyof typeof info;
  const value = info[name as Key];
  const noChildren = name === "children" && !value;

  const content = value
    ? PROP_WITH_TOW_VALUES.includes(name) && typeof value === "string"
      ? (value as string)
          .replace(/_/g, " الى ")
          .replace(/cm/g, "")
          .replace(/kg/g, "")
      : arYesOrNo(value as string)
    : noChildren
      ? constants.NOT_FOUND
      : constants.NOT_REQUIRE;

  const displayValue = Array.isArray(value) ? value.join(" | ") : content;

  return (
    <div key={index.toString()} className="m-2 flex items-center gap-x-1">
      <Icon />
      <Tooltip text={displayValue}>
        <div className="flex gap-x-2">
          <span className="line-clamp-1 font-bold text-accent">{`${label}: `}</span>
          <span className="line-clamp-1 font-bold text-primary">{`${displayValue}`}</span>
        </div>
      </Tooltip>
    </div>
  );
};

const ListItems = ({ isProfile, info }: IList) => {
  const data = isProfile
    ? labels.filter((e) => !REDUNDANT_VALUES.includes(e.name))
    : labels;
  return (
    <>
      <div className="grid h-fit grid-cols-2 grid-rows-1 gap-x-2 max-lg:grid-cols-1">
        <List
          data={data.slice(0, isProfile ? 6 : 9)}
          containerClassName="!mt-0 !pb-0 md:mr-2"
          itemClassName="!overflow-hidden"
          keyExtractor={(_, index) => index.toString()}
          renderItem={(item, index) => _renderItem(info, { ...item, index })}
        />

        <List
          data={data.slice(isProfile ? 6 : 9)}
          containerClassName="!mt-0 !pb-0 md:mr-2"
          itemClassName="my-2 !overflow-hidden"
          keyExtractor={(_, index) => index.toString()}
          renderItem={(item, index) => _renderItem(info, { ...item, index })}
        />
      </div>
    </>
  );
};

const Info = ({ user, isProfile, titleOne, titleTow }: Props) => {
  const description = (user as TProfile).bio
    ? (user as TProfile).bio
    : (user as TPreference).description;

  return (
    <div>
      <div className="mb-5 pr-5 text-xl font-bold text-primary">
        <span>{titleOne}</span>
      </div>

      <div className="m-5 rounded-lg border-2 border-secondary">
        <ListItems isProfile={isProfile} info={user} />
      </div>

      {!!description && (
        <>
          <span className="mb-5 pr-5 text-lg font-bold text-primary">
            {titleTow}
          </span>
          <div className="m-5 mt-2 px-2">
            <span className="">{description}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Info;
