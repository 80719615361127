import * as Sentry from "@sentry/react";
import constants from "configs/constants";
import useUser from "queries/user";
import { useEffect } from "react";
import { PiSmileySadThin } from "react-icons/pi";
import { TbError404 } from "react-icons/tb";
import {
  Navigate,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

type Key = keyof typeof constants.ERRORS;

const Error = () => {
  const { engaged, removed } = useUser();
  const error = useRouteError();
  const isRouteError = isRouteErrorResponse(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  if (engaged) return <Navigate to="engaged" />;
  else if (removed) return <Navigate to="/pending" />;
  return (
    <div className="h-screen overflow-hidden">
      <Navbar />
      <div className="relative grid grid-cols-1 grid-rows-1 justify-center gap-8 md:my-4 md:grid-cols-[25%,65%] xl:md:grid-cols-[20%,65%]">
        <Sidebar />
        <div className="no-scrollbar flex h-screen items-center justify-center overflow-auto border-base-300 md:h-[85vh] md:rounded-xl md:border-2">
          <article className="prose  text-center">
            {isRouteError ? (
              <TbError404 className="m-auto self-center text-center text-8xl" />
            ) : (
              <PiSmileySadThin className="m-auto self-center text-center text-8xl" />
            )}
            <p>
              {isRouteError
                ? constants.ROUTE_NOT_EXIST
                : constants.ERRORS[(error as Error)?.message as Key] ||
                  constants.ERRORS.UNEXPECTED_ERROR}
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Error;
