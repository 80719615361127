import NavIcons from "components/NavIcons";
import { ProfileIcon } from "components/ProfileIcon";
import Badge from "components/core/Badge";
import { Tooltip } from "components/theme/Tooltip";
import useAuth from "hooks/useAuth";
import useIsMobile from "hooks/useIsMobile";
import NotificationPage from "pages/notification";
import useBadges from "queries/badges";
import useUser from "queries/user";
import { AiOutlineClose } from "react-icons/ai";
import { BiHomeAlt, BiLogOutCircle, BiSearch, BiSupport } from "react-icons/bi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiMenu3Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import useUtils from "stores/utils";
import userLogout from "utils/userLogout";
import logo from "./assets/logo.svg";
import constants from "./configs/constants";

interface Props {
  showIcons?: boolean;
}

const Navbar = ({ showIcons = true }: Props) => {
  const side = useUtils((store) => store.showSidebar);
  const setShowSidebar = useUtils((store) => store.setShowSidebar);
  const { badges } = useBadges();
  const isMobile = useIsMobile();
  const { unAccepted, removed } = useUser();
  const { isGuardian } = useAuth();

  return (
    <div
      className={`navbar sticky top-0 z-50 m-0 grid grid-rows-1 bg-base-100 p-0 shadow-lg ${
        showIcons ? "grid-cols-[20%,60%,20%]" : "grid-cols-[80%,20%]"
      }`}
    >
      {/* app logo for wide screen */}
      <div className="flex items-center gap-2 text-2xl font-bold text-primary max-md:hidden">
        <img src={logo} className="w-10" />
        {constants.APP_NAME}
      </div>

      {/* menu slider btn for md screens */}
      <label className="btn btn-circle swap swap-rotate justify-center border-0 bg-transparent md:hidden">
        <input
          type="checkbox"
          checked={side}
          onChange={(e) => setShowSidebar(e.target.checked)}
        />
        <RiMenu3Line className="icon swap-off fill-current" />
        <AiOutlineClose className="icon swap-on fill-current" />
      </label>

      {/* tabs navigator */}
      {showIcons && (
        <NavIcons className="md:gap-15 gap-2 max-md:order-3 max-md:col-span-3 max-md:row-span-1 max-md:hidden sm:gap-5 lg:gap-20" />
      )}

      {/* profile + theme + notification, support icons */}
      {showIcons && (
        <div
          className={`ml-2 items-center justify-end gap-4  ${
            showIcons && "max-md:col-start-3"
          }`}
        >
          {!!isMobile && (
            <label tabIndex={0}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `${isActive && "border-b-2 border-primary text-primary"}`
                }
              >
                <div className="indicator">
                  <Tooltip text={constants.BAR_HOME}>
                    <BiHomeAlt className="icon" />
                  </Tooltip>
                </div>
              </NavLink>
            </label>
          )}

          {!!isMobile && (
            <label tabIndex={0}>
              <NavLink
                to="/search"
                className={({ isActive }) =>
                  `${isActive && "border-b-2 border-primary text-primary"}`
                }
              >
                <div className="indicator">
                  <Tooltip text={constants.BAR_SEARCH}>
                    <BiSearch className="icon" />
                  </Tooltip>
                </div>
              </NavLink>
            </label>
          )}

          <div className="dropdown dropdown-left dropdown-bottom">
            <label tabIndex={0}>
              <button>
                <div className="indicator">
                  <Tooltip text={constants.BAR_NOTIFICATIONS}>
                    <IoMdNotificationsOutline className="icon" />
                  </Tooltip>
                  <Badge value={badges?.notifications} className="m-1" />
                </div>
              </button>
            </label>

            <NotificationPage />
          </div>

          <label tabIndex={0}>
            <NavLink
              to="support"
              className={({ isActive }) =>
                `${isActive && "border-b-2 border-primary text-primary"}`
              }
            >
              <div className="indicator">
                <Tooltip text={constants.BAR_SUPPORT}>
                  <BiSupport className="icon" />
                </Tooltip>
                <Badge value={badges?.support} className="m-1" />
              </div>
            </NavLink>
          </label>

          <ProfileIcon />
        </div>
      )}

      {isGuardian && !removed && (
        <div className="ml-2 items-center justify-end gap-4 max-md:col-start-2">
          <ProfileIcon />
        </div>
      )}

      {unAccepted && !showIcons && (
        <div className={`ml-2 items-center justify-end gap-4`}>
          <label tabIndex={0}>
            <button onClick={userLogout}>
              <div className="indicator">
                <Tooltip text={constants.LOGOUT}>
                  <BiLogOutCircle className="icon text-error" />
                </Tooltip>
              </div>
            </button>
          </label>
        </div>
      )}
    </div>
  );
};

export default Navbar;
