const baseURL = import.meta.env.VITE_BASE_URL;
const ioURL = import.meta.env.VITE_SOCKET_URL;

const endpoints = {
  login: "auth/login",
  register: "auth/register",
  register_guardian: "auth/register/guardian",
  reset_password: "auth/reset_password",
  logout: "auth/logout",
  refresh_token: "auth/refresh_token",
  check_user_existence: "auth/check_user",
  send_otp: "auth/send_otp",
  verify_otp: "auth/verify_otp",
  send_twilio_otp: "auth/send_twilio",
  verify_twilio_otp: "auth/verify_twilio",
  verify_firebase: "auth/verify_firebase",
  send_email: "auth/send_email/",
  tokens: "auth/tokens",

  about: "legals/about",
  privacy: "legals/privacy",
  terms: "legals/terms",
  contacts: "legals/contacts",

  options: "configs/options",
  countries: "configs/countries",
  profile_pictures: "configs/profile_pictures",

  ads: "ads",

  blocks: "users/blocks",
  is_blocked_me: "users/is_blocked_me",
  favorites: "users/favorites",
  likes: "users/likes",
  visits: "users/visits",

  notifications: "users/notifications",
  read_notification: "users/notifications/read",

  wallet: "wallets/me",

  profiles: "users/profile",

  preferences: "users/preferences",

  me: "users/me",
  users: "users",

  guest_users: "guest/users",

  change_password: "users/me/change_password",

  badges: "users/badges",

  guardians: "guardians/me",
  guardians_users: "guardians/me/users",
  guardians_credentials: "guardians/me/users/credentials",

  engage: "users/engagements",
  engage_swear: "users/engagements/swear",
  engage_guardian: "users/engagements/guardian",

  stories: "users/stories",
  landing_stories: "landing/stories",

  statistics: "statistics",

  chats: "users/chats",
  thread: "users/thread",
  create_chat: "users/chats/create",
  message_unsent: "users/chats/unsent",
  send_message: "users/chats/send",
  hid_message: "users/chats/hide",
  report_message: "users/chats/report",
  read_message: "users/chats/:id/read",

  notes: "notes",

  supports: "supports",
  supports_read: "supports/:id/read",

  reports: "reports",

  search: "search",
  search_users: "search/users",
  new_users: "search/new",
  last_seen_users: "search/last",
  proposals_users: "search/proposals",
  matched_users: "search/matching_me",
  forMe_users: "search/for_me",
  search_landing: "landing/search",
};
export default {
  ioURL,
  baseURL,
  endpoints,
};
