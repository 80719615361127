import constants from "configs/constants";
import { AiOutlineLogout, AiOutlineMessage } from "react-icons/ai";
import { BiHomeAlt, BiSearch } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { GiDiamondRing } from "react-icons/gi";
import { MdPassword } from "react-icons/md";
import { TbUserX } from "react-icons/tb";

export const navbarIcons = [
  { label: "home", title: constants.BAR_HOME, path: "/", Icon: BiHomeAlt },
  {
    label: "engage",
    path: "/engagements",
    title: constants.BAR_ENGAGE,
    Icon: GiDiamondRing,
  },
  {
    label: "search",
    path: "/search",
    title: constants.BAR_SEARCH,
    Icon: BiSearch,
  },
  {
    label: "chats",
    path: "/chats",
    title: constants.BAR_MESSAGES,
    Icon: AiOutlineMessage,
  },
];

export const navbarSetting = [
  { path: "/profile", Icon: FaRegUser, name: "الملف الشخصي" },
  { path: "/edit/password", Icon: MdPassword, name: "تغير كلمة المرور" },
  {
    path: "/account/permanent-deletion",
    Icon: TbUserX,
    name: "حذف نهائي",
  },
  { path: "/logout", Icon: AiOutlineLogout, name: "تسجيل الخروج" },
];
