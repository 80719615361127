import classNames from "classnames";
import constants from "configs/constants";
import useIsMobile from "hooks/useIsMobile";
import { ReactNode } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useLocation } from "react-router-dom";

interface Props {
  title?: string;
  currentStep: number;
  steps: { label: string; content: ReactNode }[];
  className?: string;
  containerClassName?: string;
  next?: () => void;
  previous?: () => void;
  forceShowPrevious?: boolean;
  withoutFooter?: boolean;
  color?: "step-primary" | "step-secondary" | "step-accent";
}

const StepProgressBar = ({
  title,
  currentStep,
  steps,
  className,
  containerClassName,
  next,
  previous,
  forceShowPrevious = false,
  withoutFooter = false,
  color = "step-accent",
}: Props) => {
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const isBuildPage = pathname.includes("build");

  return (
    <div
      className={`relative h-full overflow-hidden md:h-[85vh] ${containerClassName}`}
    >
      {title && <p className="my-2 text-center text-2xl font-bold">{title}</p>}
      <div className="flex justify-center p-4 pb-0">
        <ul className="steps">
          {steps.map(({ label }, index) => (
            <li
              key={index.toString()}
              className={`step text-sm  ${index <= currentStep && color}`}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>

      <div className={`max-h-[80%] overflow-auto p-4 pt-2 ${className}`}>
        {steps[currentStep]?.content}
      </div>

      {!withoutFooter && (
        <div
          className={classNames({
            "fixed bottom-0 z-10 flex w-full bg-white px-3 py-2 md:absolute":
              true,
            "bottom-16": isMobile && !isBuildPage,
            "justify-end": Boolean(isFirstStep && !forceShowPrevious),
            "justify-between": !(isFirstStep && !forceShowPrevious),
          })}
        >
          {(forceShowPrevious || (!isFirstStep && !!previous)) && (
            <button
              className="btn btn-accent btn-outline justify-center self-center rounded-lg text-white"
              onClick={previous}
            >
              <BiChevronRight />
              {constants.PREVIOUS}
            </button>
          )}

          {!!next && (
            <button
              className="btn btn-accent btn-outline justify-center self-center rounded-lg text-white"
              onClick={next}
            >
              {isLastStep ? constants.CONFIRM : constants.NEXT}
              <BiChevronLeft />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default StepProgressBar;
