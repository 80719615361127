import apiConfig from "configs/api-v2";
import { PropsType } from "pages/search/props";
import UserItem from "types/UserItem";
import apiClient from "./client-v2";

const endpoints = apiConfig.endpoints;

interface Response {
  results: UserItem[];
  next?: number;
  previous?: number;
}

const getForGuest = (data: PropsType, page: number) =>
  apiClient.post<Response>(`${endpoints.search_landing}?page=${page}`, data);

const get = (data: PropsType, page: number) =>
  apiClient.post<Response>(`${endpoints.search}?page=${page}`, data);

const users = (
  page: number,
  type: "proposals" | "last" | "matched" | "forme" | "new",
) =>
  apiClient.get<UserItem[]>(`${endpoints.search_users}/${type}?page=${page}`);

export default {
  get,
  getForGuest,
  users,
};
